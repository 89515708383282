.link {
    background-color: rgb(140, 0, 255);
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    color: white;
    font-size: 14px;
    transition: all 300ms;
    border: 1px solid rgb(140, 0, 255);
}

.active .link {
    background-color: transparent;
    color: rgb(140, 0, 255);
    border-right: 5px solid;
    box-shadow: 1px 1px 1px;
}

